import * as React from "react";
import * as styles from "./terms-conditions.module.css";

import { FunctionComponent } from "react";
import Layout from '../components/Base/Layout';
import { SEO } from "../components/Base/Seo";

const TermsConditions: FunctionComponent = () => {

  return (
    <Layout>
      <div className={styles.termsConditions}>
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.title}>
              <b className={styles.display}>Terms and Conditions</b>
            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Welcome to CDIHealth.net
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  By using this website, we presume that you agree to follow these terms and conditions. Please refrain from using CDI Health website if you do not agree to comply with all the terms and conditions outlined on this page.
                </div>
              </div>

            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>Cookies</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We utilize cookies to personalize your online experience on our website. By using CDI Health, you consent to the use of cookies as outlined in the Privacy Policy.

                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Cookies are text files placed on your hard drive by a web page server. They are utilized to execute programs or send viruses to your device. Each cookie is uniquely assigned to you and can only be accessed by a server within the domain that issued the cookie to your computer.

                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Most interactive websites utilize cookies to retrieve user details for each visit. Our website uses cookies to enable the functionality of specific areas and enhance the user experience. Additionally, some of our affiliate/advertising partners may also utilize cookies.

                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>License</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Unless specified otherwise, CDI Health and/or its licensors possess the intellectual property rights to all content on CDI Health website. All intellectual property rights are safeguarded. You are permitted to access this content on CDI Health for personal use, but you must comply with the limitations specified in these terms and conditions.

                </div>
              </div>
              <div className={styles.p6}>
                <div className={styles.caption}>
                  <p className={styles.youMustNot}>You are prohibited from::</p>
                  <p className={styles.youMustNot}>&nbsp;</p>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      Reproducing and republishing materials from The CDI Health

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Selling or sub-licensing material from The CDI Health

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Duplicating or copying material from The CDI Health

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Redistributing content from The CDI Health

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      This agreement will commence from the present date.

                    </li>
                  </ul>

                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Sections of this website provide a platform for users to share opinions and information in specific areas. CDI Health does not screen, edit, publish, or review comments before they appear on the website. Comments do not represent the views of CDI Health, its agents, or affiliates. They solely convey the perspectives of the individuals who post them. Within the bounds of applicable laws, CDI Health cannot be held accountable for comments or for any damages resulting from the use, posting, or appearance of comments on this website.

                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  CDI Health retains the right to monitor all comments and to delete any that are deemed inappropriate, offensive, or in violation of these terms and conditions.

                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  By posting comments on our website, you confirm and assure that:

                </div>
              </div>
              <div className={styles.p6}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      You have the right to post the comments and possess all necessary licenses and approvals to do so.

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      The comments do not infringe upon any intellectual property rights, including copyrights, patents, or trademarks of any third party.

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      The comments do not contain defamatory, libelous, offensive, indecent, or unlawful content that violates privacy.

                    </li>
                    <li>
                      The comments will not be utilized for soliciting business, promoting commercial activities, or engaging in unlawful activities.

                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  You also give CDI Health a non-exclusive license to use, modify, and authorize others to use and modify any of your comments in various forms, formats, or media.

                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Hyperlinking to our Content</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The following organizations may link to our website:
                </div>
              </div>
            </div>
            <div className={styles.textbody7}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      Government agencies
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Search engines
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      News organizations
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Online directory distributors

                    </li>
                    <li>
                      System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups

                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We may approve hyperlinking requests from the following organizations provided (a) The link should not reflect negatively on us or our affiliated businesses; (b) The entity linking to us does not have any adverse history with us; (c) The link provides general resource information.:

                </div>
              </div>
            </div>

            <div className={styles.textbody7}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.reproduceAndRepublishMateri}>
                    <li className={styles.reproduceAndRepublish}>
                      Business information sources

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Community sites

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Charitable organizations
                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Directories

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Portals

                    </li>
                    <li className={styles.reproduceAndRepublish}>
                      Accounting and law consultation firms
                    </li>
                    <li>Educational and trade associations
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={styles.subtitle}>
              <div className={styles.display3}>Content Liability</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <p className={styles.caption23}>
                  We are not accountable for any content displayed on your website. By agreeing, you agree to defend and safeguard us against any claims arising from your website. Links on any website must not be defamatory, obscene, criminal, or infringe upon third-party rights.

                </p>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Privacy</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <span>{`Please review the `}</span>
                  <a href="/privacy-policy" className={styles.privacyPolicy}>Privacy Policy.</a>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>
                Link Removal from Our Website:

              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you come across any offensive links on our website, feel free to inform us at any time. While we will consider requests to remove links, we are not obliged to do so or directly respond to you.

                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We do not guarantee the accuracy of the information on this website, its completeness, or its continuous availability. We also do not promise to keep the website's material up to date.

                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display3}>Disclaimer</div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  To the fullest extent provided by law, we disclaim all representations, warranties, and conditions related to our website and its use. This disclaimer does not limit or exclude liability for death or personal injury, fraud or fraudulent misrepresentation, or any liabilities not permitted by law.

                </div>
              </div>
            </div>

            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The limitations and liability exclusions outlined in this disclaimer apply to all liabilities under the disclaimer, covering contractual, tort, and statutory duty breaches.

                </div>
              </div>
            </div>
            <div className={styles.textbody5}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  As long as the website and its services are offered free of charge, we will not be responsible for any nature of loss or damage.

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default TermsConditions;

export const Head = () => (
  <SEO title="CDI Health | Terms&Conditions" description="Terms&Conditions page" />
)
